.supporters {
  img {
    max-height: 5rem;
  }
}

label.required:after {
  content: ' *';
}

.sidebar {
  h2 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.1rem;
  }

  .grid-item {
    max-width: 100%;
  }
}

.quick-search {
  form {
    background: $white;
    border: solid 1px $gray-300;
    border-radius: .2rem;
    display: flex;
    margin: .25rem 0;
    padding: .2rem 2rem .2rem .4rem;
    position: relative;

    &:focus-within {
      border-color: $input-focus-border-color;
      box-shadow: $input-focus-box-shadow;
    }

    input[type='text'] {
      background: none;
      border: 0;
      color: $gray-700;
      font-size: .875rem;
      font-weight: 600;
      width: 12rem;

      &:focus {
        outline: none;
        transition: .2s ease-in-out;
        // width: 12rem;
      }

      @include media-breakpoint-down(xs) {
        width: 100%;
      }
    }

    button {
      background: none;
      border: 0;
      color: $gray-400;
      display: block;
      line-height: 1.75rem;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      width: 2rem;

      &:focus {
        outline: 0;
      }
    }
  }
}

// Make these pages full-width
.controller-events-index,
.controller-home-index,
.controller-organizations-index,
.controller-products-index,
.controller-users-index,
.controller-games-show,
.controller-games-answer,
.controller-game-steps-show {
  main {
    &.container {
      max-width: none !important;
    }
  }
}

.archived {
  text-decoration: line-through;
}

.main-button,
a.main-button {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: .5rem 0 0;
  text-decoration: none;

  .header_img {}

  .title {
    background-color: $theme-color-light-green;
    border-radius: .15rem;
    box-shadow: 5px 5px 0 $theme-color-shadow-green;
    color: $white;
    display: inline-block;
    font-family: AmsiPro-Black;
    font-size: 2rem;
    margin: 0 0 1rem;
    min-width: 18rem;
    padding: 0 .95rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
  }

  .subtitle {
    color: $theme-color-dark-green;
    font-family: AmsiPro-Bold;
    margin-bottom: .5rem;
    text-align: center;
    text-transform: uppercase;
  }
}

.visual {
  background-color: #efefef;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;

  img {
    width: 100%;
  }

  .text {
    bottom: 0;
    max-height: 100%;
    overflow: hidden;
    padding: 1rem;
    position: absolute;

    .subtitle,
    h1 {
      color: $white;
      font-weight: 600;
      margin: 0;
      text-shadow: 0 0 .15rem $black;
    }

    @include media-breakpoint-down(xs) {
      .subtitle {
        display: none;
      }

      h1 {
        font-size: 2rem;
      }
    }
  }
}

.text-transform-lowercase {
  text-transform: lowercase;
}
