/*************************** Theme Colours****************************/
// Define theme colour scheme
$theme-color-primary: #ff1432;
$theme-color-light-pink: #fde6e8;
$theme-color-light-green: #63e4a9;
$theme-color-light-green-background: #d1f5ee;
$theme-color-dark-green: #08cd89;
$theme-color-shadow-green: #176768;

$theme-text-color-primary: #000;
$theme-text-color-secondary: lighten($theme-text-color-primary, 25%);
$theme-text-color-light: lighten($theme-text-color-primary, 40%);

$theme-bg-light: lighten($theme-text-color-secondary, 70%);
$theme-bg-dark: $theme-color-primary;
$theme-bg-color: $theme-color-primary;

$theme-border-color: #efefef;
$theme-divider-color: #efefef;

$theme-divider: lighten($theme-text-color-primary, 75%);

$theme-color-new: #5ab66e;
$theme-error: #ec575b;

$map-overlay-color: #648bbb;

$smoky-white: #f5f5f5;

$brand-colors: (
  'facebook': #3b5998,
  'youtube': #e73323,
  'twitter': #1da1f2,
  'instagram': black,
  'linkedin': #3576b0,

  'google': #d73d32,
  'github': #24282d,
  'producthunt': #da552f,
  'wordpress': #028cb0
);

$single-col-max: 800px;

$theme-success-color: #5cb377;
$theme-warning-color: #eebf41;
$theme-info-color: #5b99ea;
$theme-danger-color: #d26d69;

// *************************** Variables Section. *****************************
// Create variables to override the default value for variables used in the Bootstrap SCSS files.

$gray-050: lighten($theme-text-color-secondary, 69%);
$gray-100: lighten($theme-text-color-secondary, 65%);
$gray-200: lighten($theme-text-color-secondary, 55%);
$gray-300: lighten($theme-text-color-secondary, 50%);
$gray-400: lighten($theme-text-color-secondary, 45%);
$gray-500: lighten($theme-text-color-secondary, 40%);
$gray-600: lighten($theme-text-color-secondary, 30%);
$gray-700: lighten($theme-text-color-secondary, 20%);
$gray-800: lighten($theme-text-color-secondary, 10%);
$gray-900: $theme-text-color-primary;
$gray-base: $gray-800;

$theme-colors: (
  'primary': $theme-color-primary,
  'secondary': $theme-text-color-secondary,
  'success': $theme-success-color,
  'danger': $theme-danger-color,
  'warning': $theme-warning-color,
  'info': $theme-info-color,
  'light-pink': $theme-color-light-pink,
  'light-green': $theme-color-light-green,
  'light-green-background': $theme-color-light-green-background,
  'shadow-green': $theme-color-shadow-green,
  'map': $map-overlay-color,
  'disabled': $gray-100
);

$body-color: $theme-text-color-primary;
$breadcrumb-bg: #fff;
$breadcrumb-margin-bottom: 0;
$input-border-color: #ced4da;
$input-group-addon-bg: $input-border-color;
$table-border-color: $gray-100;
$input-disabled-bg: $gray-100;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 959px,
  xl: 960px
)
