table {
  &.data-table {
    font-size: .9rem;
    width: 100%;

    td,
    th,
    tr {
      line-height: 1.3 * $line-height;
    }

    td,
    th {
      padding: 0 .8em;
    }

    tr {
      border: solid 1px $border-color;

      &:hover {
        background: #ffffe2;
      }
    }

    th {
      text-align: left;
    }
  }
}

.table-item {
  .visual {
    width: 8rem;
  }
}
