.table-borderless {
  td:first-child, th:first-child {
      padding-left: 0;
  }

  td:last-child, th:last-child {
      padding-right: 0;
  }
}

tbody {
  tr.clickable {
    cursor: pointer;
    &:hover {
      background-color: #f6f8fa;
    }
  }
}
