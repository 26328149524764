.ck {
  &.ck-editor__main > {
    .ck-editor__editable {
      &.ck-read-only {
        background: $gray-100;
      }
    }
  }
}

.ck-content {
  .image-style-align-left,
  .image-style-align-right {
    margin-top: .25rem !important;
  }

  p {
    @include clearfix;
  }
}
