.notice {
  margin: $default_v_margin 0;
  padding: $default_padding;
  border: solid 1px black;
  text-align: center;
  &.not-verified {
    border-color: $hb-red;
    color: $hb-red;
    background: lighten($hb-red, 42%);
  }
}

