.embed {
  .filterable-content.display-map [data-controller="map"] {
    height: calc(100vh - 6.75rem);

    @include media-breakpoint-down(xs) {
      height: calc(100vh - 7.75rem);
    }
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .col-12 {
    padding-left: 0;
    padding-right: 0;
  }

  .display-map .top-paging-bar {
    right: 0.5rem;
  }
}

.region-map-links {
  height: 6rem;

  ul {
    border-bottom: solid 1px #ececec;
    border-top: solid 1px #ececec;
    padding: 0;

    li {
      display: inline-block;
      padding: 0.25rem 0;
      margin-right: 2rem;
      border-top: transparent 3px solid;

      &.active {
        border-top: $theme-color-primary 3px solid;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    font-size: 90%;

    ul {
      li {
        margin-right: 1.5rem;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    font-size: 85%;
    height: 7rem;

    ul {
      li {
        margin-right: 1.15rem;
        padding: .15rem 0;
      }
    }
  }
}

.map-postcode-search {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: .5rem;
  padding: .25rem;
  position: absolute;
  right: .25rem;
  top: 7rem;
  z-index: 100;

  input {
    width: 8rem;
  }

  @include media-breakpoint-down(xs) {
    top: 8rem;
  }
}

.overflow-x-scroll {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

$syl-link-color: #eb5f58;
$syl-button: #f8d0c7;
$syl-button-selected: $syl-link-color;

.supportyourlocals {
  :not(.fa, .fas, .far) {
    font-family: "Open Sans", sans-serif;
  }

  a {
    color: $syl-link-color;
  }

  .region-map-links {
    li {
      &.active {
        border-top-color: $syl-link-color;
      }
    }
  }

  .form-control:focus {
    border-color: $syl-link-color;
    box-shadow: 0 0 0 0.2rem rgba($syl-link-color, .25);
  }

  .grid-item .title {
    color: $syl-link-color;
  }

  .close-popup {
    color: $syl-button-selected;

    .fa-inverse {
      color: $white;
    }
  }

  .display-options {
    display: none;
  }

  .resource-type-selector {
    .resources {
      a,
      button {
        background-color: $syl-button;
        color: $white;

        &.active {
          background-color: $syl-button-selected;
        }
      }
    }
  }

  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Open Sans Regular"), local("OpenSans-Regular"),
      url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2) format("woff2");
  }
  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
      url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UNirkOUuhpKKSTjw.woff2) format("woff2");
  }
}
