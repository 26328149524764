.overlay {
  background: $white;
  box-shadow: 0 0 10px #777;
  height: 100vh;
  left: 0;
  overflow-y: auto;
  padding: 1rem;
  position: absolute;
  top: 0;
  width: 50vw;
  z-index: 1000;

  .close-popup {
    color: $theme-color-primary;
    cursor: pointer;
    font-size: .8em;
    margin-right: .25rem;
    position: fixed;
    right: 50vw;
    top: .4rem;
    z-index: 1000;

    &:hover {
      color: $theme-color-light-pink;

      .fa-inverse {
        color: $theme-color-primary;
      }
    }
  }
}

@media(max-width: $resp_break) {
  .overlay {
    width: 100vw;

    .close-popup {
      right: 0;
    }
  }
}
