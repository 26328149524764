@media print {
  nav.breadcrumb,
  .footer,
  .no-print {
    display: none !important;
  }

  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 100%;
  }

  @page {
    max-height: 100%;
    max-width: 100%;
  }
}
