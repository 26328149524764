.card-pre-bootstrap.hotspot {
  .content {
    position: relative;

    img {
      width: 100%;
    }
  }

  .content {
    padding: $default-h-padding $default-h-padding 0;
  }

  footer {
    .button {
      color: white;
      background: $hb-red;
      border: none;
    }
  }
}
