.button,
a.button {
  background: $white;
  border: solid 1px $border-color-button;
  border-radius: $border-radius;
  color: $border-color-button-hover;
  cursor: pointer;
  display: inline-block;
  font-size: .85em;
  line-height: 1.5 * $line-height;
  padding: 0 $default-h-padding;

  &:hover {
    border-color: $border-color-button-hover;
    color: $border-color-button-hover;
  }
}

a.button {
  text-decoration: none;
}
