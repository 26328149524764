.home-intro {
  display: flex;
  margin-top: 1em;
  text-align: center;

  &.description,
  &.title,
  &.video {
    .one,
    .three {
      flex: 1;
    }

    .two {
      flex: 2;
      max-width: 30rem;
    }
  }

  &.title {
    background-position: 0 0;
    background-image: linear-gradient(0deg, white 0, white 2em, $bg_gradient_start 2em, white 10em);
    height: 12em;

    h1 {
      margin-top: 1.2em;
    }

    .one {
      background: image-url("../../assets/images/lekker_van_dichtbij.png") no-repeat;
      background-size: 12em auto;
      background-position: center 2.6em;
    }

    .three {
      background: image-url("../../assets/images/hallo_boeren.png") no-repeat;
      background-size: 13em auto;
      background-position: center 3.7em;
    }
  }

  &.description {
    .two {
      text-align: center;
      max-width: 46rem;
    }
  }

  &.animation {
    width: 600px;
    max-width: 100%;
    margin: 1rem auto;
    text-align: center;

    iframe {
      max-width: 100%;
    }
  }
}

.join {
  margin: 1em;
  text-align: center;
}

@media (max-width: $resp_break) {
  .home-intro {
    display: block;

    .one,
    .three,
    .two {
      display: block;
      width: 100%;
      max-width: 100%;
    }

    &.title {
      h1 {
        font-size: 1.2rem;
      }

      .one {
        height: 6em;
        background-size: 8em auto;
        background-position: center bottom;
      }

      .two {
        max-width: unset;
      }
    }
  }
}
