.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;

  .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 1.75rem;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;

    .select2-selection__rendered {
      display: block;
      overflow: hidden;
      padding-left: .5rem;
      padding-right: 1.25rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .select2-selection__clear {
      position: relative;
    }
  }

  &[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    padding-left: 1.25rem;
    padding-right: .5rem;
  }

  .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 2rem;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;

    .select2-selection__rendered {
      display: inline-block;
      overflow: hidden;
      padding-left: .5rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .select2-search--inline {
    float: left;

    .select2-search__field {
      border: 0;
      box-sizing: border-box;
      font-size: 100%;
      margin-top: 5px;
      padding: 0;

      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
      }
    }
  }
}

.select2-dropdown {
  background-color: $white;
  border: 1px solid $gray-200;
  border-radius: $border-radius-lg;
  box-sizing: border-box;
  display: block;
  left: -100000px;
  position: absolute;
  width: 100%;
  z-index: 2051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;

  &[aria-selected] {
    cursor: pointer;
  }
}

.select2-container--open {
  .select2-dropdown {
    left: 0;
  }

  .select2-dropdown--above {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .select2-dropdown--below {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.select2-search--dropdown {
  display: block;
  padding: 4px;

  .select2-search__field {
    box-sizing: border-box;
    padding: .25rem;
    width: 100%;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }

  &.select2-search--hide {
    display: none;
  }
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: $white;
  filter: alpha(opacity = 0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.select2-container--bootstrap {
  display: block;

  .select2-selection {
    background-color: $white;
    border: 1px solid $gray-200;
    border-radius: $border-radius;
    color: $gray-700;
    outline: 0;

    &.form-control {
      border-radius: $border-radius;
    }
  }

  .select2-search--dropdown .select2-search__field {
    background-color: $white;
    border: 1px solid $gray-200;
    border-radius: $border-radius;
    color: $gray-700;
  }

  .select2-search__field {
    outline: 0;

    &:-moz-placeholder,
    &::-webkit-input-placeholder {
      color: $gray-600;
    }

    &::-moz-placeholder {
      color: $gray-600;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $gray-600;
    }
  }

  .select2-results__option {
    padding: .375rem .75rem;

    &[role=group] {
      padding: 0;
    }

    &[aria-disabled=true] {
      color: $gray-600;
      cursor: pointer;
    }

    &[aria-selected=true] {
      background-color: $gray-100;
      color: $gray-base;
    }

    &[aria-selected=true]::before {
      content: '✔';
      padding-right: .5rem;
    }
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: $primary;
    color: $white;
  }

  .select2-results__option .select2-results__option {
    padding: .375rem .75rem;

    .select2-results__group {
      padding-left: 0;
    }

    .select2-results__option {
      margin-left: -.75rem;
      padding-left: 1.5rem;

      .select2-results__option {
        margin-left: -1.5rem;
        padding-left: 2.25rem;

        .select2-results__option {
          margin-left: -2.25rem;
          padding-left: 3rem;

          .select2-results__option {
            margin-left: -3rem;
            padding-left: 3.75rem;

            .select2-results__option {
              margin-left: -3.75rem;
              padding-left: 4.5rem;
            }
          }
        }
      }
    }
  }

  .select2-results__group {
    color: $gray-600;
    display: block;
    padding: .375rem .75rem;
    font-size: .765625rem;
    line-height: 1.5;
    white-space: nowrap;
  }

  &.select2-container--focus .select2-selection {
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    border-color: $input-focus-border-color;
  }

  &.select2-container--open {
    .select2-selection {
      transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
      border-color: $input-focus-border-color;

      .select2-selection__arrow b {
        border-color: transparent transparent $gray-600 transparent;
        border-width: 0 .3rem .3rem .3rem;
      }
    }

    &.select2-container--below .select2-selection {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-color: transparent;
    }

    &.select2-container--above .select2-selection {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top-color: transparent;
    }
  }

  .select2-selection__clear {
    color: $gray-600;
    cursor: pointer;
    float: right;
    font-weight: bold;
    padding: 0 .4rem;
    font-size: 1rem;
    border-radius: $border-radius;
    position: absolute;
    right: .25rem;
    line-height: 1.4rem;

    &:hover {
      // color: $white;
      background-color: $gray-200;
      color: $gray-800;
    }
  }

  &.select2-container--disabled {
    .select2-selection {
      border-color: $input-border-color;
      cursor: pointer;
    }

    .select2-search__field {
      cursor: pointer;
    }

    .select2-selection,
    .select2-selection--multiple .select2-selection__choice {
      background-color: $gray-100;
    }

    .select2-selection--multiple .select2-selection__choice__remove,
    .select2-selection__clear {
      display: none;
    }
  }

  .select2-dropdown {
    border-color: $input-focus-border-color;
    overflow-x: hidden;
    margin-top: -1px;
  }

  .select2-dropdown--above {
    margin-top: 1px;
  }

  .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto;
  }

  .select2-selection--single {
    height: calc(2.0625rem + 4px);
    line-height: 1.5;
    padding: .375rem 1.2rem .375rem .75rem;

    .select2-selection__arrow {
      position: absolute;
      bottom: 0;
      right: 1.2rem;
      top: 0;

      b {
        border-color: $gray-600 transparent transparent transparent;
        border-style: solid;
        border-width: .3rem .3rem 0 .3rem;
        height: 0;
        left: 0;
        position: absolute;
        top: 45%;
        width: 0;
      }
    }

    .select2-selection__rendered {
      color: $gray-700;
      padding: 0;
    }

    .select2-selection__placeholder {
      color: $gray-600;
    }
  }

  .select2-selection--multiple {
    // min-height: calc(2.0625rem + 4px);
    padding: 0;
    height: auto;

    .select2-selection__rendered {
      display: block;
      list-style: none;
      margin: 0;
      overflow: hidden;
      padding: .1875rem .1875rem 0;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .select2-selection__placeholder {
      color: $gray-600;
      float: left;
      margin-top: 5px;
    }

    .select2-selection__choice {
      color: $white;
      background: $black;
      border-radius: $border-radius;
      cursor: default;
      float: left;
      margin-right: .1875rem;
      margin-bottom: .1875rem;
      padding: 0 .375rem 0 0;
      font-weight: 600;
    }

    .select2-search--inline .select2-search__field {
      background: transparent;
      margin-top: 0;
      min-width: 5rem;
      padding: .25rem .5rem;
      // height: 1.5rem;
      // Needed to get a bit of space for the placeholder
      width: 100% !important;
    }

    .select2-selection__choice + .select2-search--inline .select2-search__field {
      padding-left: 0;
    }

    .select2-selection__choice__remove {
      background: $black;
      border-radius: $border-radius 0 0 $border-radius;
      color: $white;
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      margin-right: .1875rem;
      padding: .125rem .5rem;
      text-align: center;

      &:hover {
        background: lighten($black, 50%);
      }
    }

    .select2-selection__clear {
      // margin-top: .1875rem;
    }
  }

  .select2-selection--single.input-sm {
    border-radius: $border-radius-sm;
    font-size: .765625rem;
    height: calc(1.648438rem + 2px);
    line-height: 1.5;
    padding: .25rem 1.4rem .25rem .5rem;
  }
}
@media screen and (prefers-reduced-motion: reduce) {
  .select2-container--bootstrap {
    &.select2-container--focus .select2-selection,
    &.select2-container--open .select2-selection {
      transition: none;
    }
  }
}

.form-group-sm .select2-container--bootstrap .select2-selection--single,
.input-group-sm .select2-container--bootstrap .select2-selection--single {
  border-radius: $border-radius-sm;
  font-size: .765625rem;
  height: calc(1.648438rem + 2px);
  line-height: 1.5;
  padding: .25rem 1.4rem .25rem .5rem;
}

.form-group-sm .select2-container--bootstrap .select2-selection--single .select2-selection__arrow b,
.input-group-sm .select2-container--bootstrap .select2-selection--single .select2-selection__arrow b,
.select2-container--bootstrap .select2-selection--single.input-sm .select2-selection__arrow b {
  margin-left: -.25rem;
}

.form-group-sm .select2-container--bootstrap .select2-selection--multiple,
.input-group-sm .select2-container--bootstrap .select2-selection--multiple,
.select2-container--bootstrap .select2-selection--multiple.input-sm {
  border-radius: $border-radius-sm;
  min-height: calc(1.648438rem + 2px);
}

.form-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-selection__choice,
.input-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-selection__choice,
.select2-container--bootstrap .select2-selection--multiple.input-sm .select2-selection__choice {
  font-size: .765625rem;
  line-height: 1.5;
  margin: -.75rem 0 0 .25rem;
  padding: 0 .25rem;
}

.form-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field,
.input-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field,
.select2-container--bootstrap .select2-selection--multiple.input-sm .select2-search--inline .select2-search__field {
  padding: 0 .5rem;
  font-size: .765625rem;
  height: calc(1.648438rem + 2px) (-2);
  line-height: 1.5;
}

.form-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-selection__clear,
.input-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-selection__clear,
.select2-container--bootstrap .select2-selection--multiple.input-sm .select2-selection__clear {
  margin-top: .25rem;
}

.form-group-lg .select2-container--bootstrap .select2-selection--single,
.input-group-lg .select2-container--bootstrap .select2-selection--single,
.select2-container--bootstrap .select2-selection--single.input-lg {
  border-radius: $border-radius-lg;
  font-size: 1.09375rem;
  height: calc(2.640625rem + 2px);
  line-height: 1.5;
  padding: .5rem 1.9rem .5rem 1rem;
}

.form-group-lg .select2-container--bootstrap .select2-selection--single .select2-selection__arrow,
.input-group-lg .select2-container--bootstrap .select2-selection--single .select2-selection__arrow,
.select2-container--bootstrap .select2-selection--single.input-lg .select2-selection__arrow {
  width: .3rem;
}

.form-group-lg .select2-container--bootstrap .select2-selection--single .select2-selection__arrow b,
.input-group-lg .select2-container--bootstrap .select2-selection--single .select2-selection__arrow b,
.select2-container--bootstrap .select2-selection--single.input-lg .select2-selection__arrow b {
  border-width: .3rem .3rem 0 .3rem;
  margin-left: -.5rem;
  margin-top: -.15rem;
}

.form-group-lg .select2-container--bootstrap .select2-selection--multiple,
.input-group-lg .select2-container--bootstrap .select2-selection--multiple,
.select2-container--bootstrap .select2-selection--multiple.input-lg {
  border-radius: .3rem;
  min-height: calc(2.640625rem + 2px);
}

.form-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-selection__choice,
.input-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-selection__choice,
.select2-container--bootstrap .select2-selection--multiple.input-lg .select2-selection__choice {
  border-radius: $border-radius;
  font-size: 1.09375rem;
  line-height: 1.5;
  margin: -.5rem 0 0 .5rem;
  padding: 0 .5rem;
}

.form-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field,
.input-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field,
.select2-container--bootstrap .select2-selection--multiple.input-lg .select2-search--inline .select2-search__field {
  padding: 0 1rem;
  font-size: 1.09375rem;
  height: calc(2.640625rem + 2px) (-2);
  line-height: 1.5;
}

.form-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-selection__clear,
.input-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-selection__clear,
.select2-container--bootstrap .select2-selection--multiple.input-lg .select2-selection__clear {
  margin-top: .5rem;
}

.input-group-lg .select2-container--bootstrap .select2-selection.select2-container--open .select2-selection--single .select2-selection__arrow b,
.select2-container--bootstrap .select2-selection.input-lg.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent $gray-600 transparent;
  border-width: 0 .3rem .3rem .3rem;
}

.select2-container--bootstrap[dir="rtl"] {
  .select2-selection--single {
    padding-left: 1.2rem;
    padding-right: .75rem;

    .select2-selection__rendered {
      padding-right: 0;
      padding-left: 0;
      text-align: right;
    }

    .select2-selection__clear {
      float: left;
    }

    .select2-selection__arrow {
      left: .75rem;
      right: auto;

      b {
        margin-left: 0;
      }
    }
  }

  .select2-selection--multiple {
    .select2-search--inline,
    .select2-selection__choice,
    .select2-selection__placeholder {
      float: right;
    }

    .select2-selection__choice {
      margin-left: 0;
      margin-right: .375rem;
    }

    .select2-selection__choice__remove {
      margin-left: 2px;
      margin-right: auto;
    }
  }
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: $green;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: $white;
  background-color: rgba(77, 189, 116, 0.8);
  border-radius: $border-radius-sm;
}

.custom-select.is-valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.was-validated .form-control:valid {
  border-color: $green;
}

.custom-select.is-valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .form-control:valid:focus {
  border-color: $green;
  box-shadow: 0 0 0 .2rem rgba(77, 189, 116, 0.25);
}

.was-validated .form-control:valid ~ {
  .valid-feedback,
  .valid-tooltip {
    display: block;
  }
}

.form-control.is-valid ~ {
  .valid-feedback,
  .valid-tooltip {
    display: block;
  }
}

.was-validated .custom-select:valid ~ {
  .valid-feedback,
  .valid-tooltip {
    display: block;
  }
}

.custom-select.is-valid ~ {
  .valid-feedback,
  .valid-tooltip {
    display: block;
  }
}

.was-validated .form-control-file:valid ~ {
  .valid-feedback,
  .valid-tooltip {
    display: block;
  }
}

.form-control-file.is-valid ~ {
  .valid-feedback,
  .valid-tooltip {
    display: block;
  }
}

.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: $green;
}

.was-validated .form-check-input:valid ~ {
  .valid-feedback,
  .valid-tooltip {
    display: block;
  }
}

.form-check-input.is-valid ~ {
  .valid-feedback,
  .valid-tooltip {
    display: block;
  }
}

.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: $green;
}

.custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
  background-color: lighten($green, 25%);
}

.was-validated .custom-control-input:valid ~ {
  .valid-feedback,
  .valid-tooltip {
    display: block;
  }
}

.custom-control-input.is-valid ~ {
  .valid-feedback,
  .valid-tooltip {
    display: block;
  }
}

.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
  background-color: lighten($green, 25%);
}

.custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px $gray-200, 0 0 0 .2rem rgba(77, 189, 116, 0.25);
}

.custom-file-input.is-valid ~ .custom-file-label,
.was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: $green;
}

.custom-file-input.is-valid ~ .custom-file-label::before,
.was-validated .custom-file-input:valid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ {
  .valid-feedback,
  .valid-tooltip {
    display: block;
  }
}

.custom-file-input.is-valid ~ {
  .valid-feedback,
  .valid-tooltip {
    display: block;
  }
}

.custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 .2rem rgba(77, 189, 116, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: $red;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: $white;
  background-color: rgba(248, 108, 107, 0.8);
  border-radius: $border-radius-sm;
}

.custom-select.is-invalid,
.form-control.is-invalid,
.is-invalid ~ .select2 .select2-selection,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
  border-color: $red;
}

.custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.is-invalid ~ .select2 .select2-selection:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: $red;
  box-shadow: 0 0 0 .2rem rgba(248, 108, 107, 0.25);
}

.was-validated .form-control:invalid ~ {
  .invalid-feedback,
  .invalid-tooltip {
    display: block;
  }
}

.form-control.is-invalid ~ {
  .invalid-feedback,
  .invalid-tooltip {
    display: block;
  }
}

.was-validated .custom-select:invalid ~ {
  .invalid-feedback,
  .invalid-tooltip {
    display: block;
  }
}

.custom-select.is-invalid ~ {
  .invalid-feedback,
  .invalid-tooltip {
    display: block;
  }
}

.was-validated .form-control-file:invalid ~ {
  .invalid-feedback,
  .invalid-tooltip {
    display: block;
  }
}

.form-control-file.is-invalid ~ {
  .invalid-feedback,
  .invalid-tooltip {
    display: block;
  }
}

.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: $red;
}

.was-validated .form-check-input:invalid ~ {
  .invalid-feedback,
  .invalid-tooltip {
    display: block;
  }
}

.form-check-input.is-invalid ~ {
  .invalid-feedback,
  .invalid-tooltip {
    display: block;
  }
}

.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: $red;
}

.custom-control-input.is-invalid ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  background-color: lighten($red, 40%);
}

.was-validated .custom-control-input:invalid ~ {
  .invalid-feedback,
  .invalid-tooltip {
    display: block;
  }
}

.custom-control-input.is-invalid ~ {
  .invalid-feedback,
  .invalid-tooltip {
    display: block;
  }
}

.custom-control-input.is-invalid:checked ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before {
  background-color: lighten($red, 25%);
}

.custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px $gray-200, 0 0 0 .2rem rgba(248, 108, 107, 0.25);
}

.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: $red;
}

.custom-file-input.is-invalid ~ .custom-file-label::before,
.was-validated .custom-file-input:invalid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ {
  .invalid-feedback,
  .invalid-tooltip {
    display: block;
  }
}

.custom-file-input.is-invalid ~ {
  .invalid-feedback,
  .invalid-tooltip {
    display: block;
  }
}

.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 .2rem rgba(248, 108, 107, 0.25);
}

.input-group > {
  .select2-hidden-accessible {
    &:first-child + .select2-container--bootstrap > .selection > .select2-selection {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &.form-control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:not(:first-child) {
      + .select2-container--bootstrap:not(:last-child) > .selection > .select2-selection {
        border-radius: 0;

        &.form-control {
          border-radius: 0;
        }
      }

      &:not(:last-child) + .select2-container--bootstrap:last-child > .selection > .select2-selection {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        &.form-control {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }

  .select2-container--bootstrap {
    display: table;
    table-layout: fixed;
    position: relative;
    z-index: 2;
    width: 100%;
    margin-bottom: 0;

    > .selection > .select2-selection.form-control {
      float: none;
    }

    &.select2-container--focus,
    &.select2-container--open {
      z-index: 3;
    }
    vertical-align: top;

    .input-group-btn {
      vertical-align: top;

      .btn {
        vertical-align: top;
      }
    }
  }
}

.form-control.select2-hidden-accessible {
  position: absolute !important;
  width: 1px !important;
}

select[readonly] {
  &.select2-hidden-accessible {
    & + .select2-container {
      pointer-events: none;
      touch-action: none;

      .select2-selection {
        background: #e3e7ea;
        box-shadow: none;
      }

      .select2-selection__arrow,
      .select2-selection__clear {
        display: none;
      }
    }
  }
}
