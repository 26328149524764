.badge {
  position: relative;
  overflow: visible;
  svg.triangle {
    height: 25%;
    position: absolute;
    bottom: -25%;
    left: 20%;
    path {
      fill: $hb-red;
    }
  }
}
