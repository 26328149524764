h1 {
  color: $theme-color-primary;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  text-align: center;
}

h2,
h3,
h4 {
  margin-top: 1rem;
  margin-bottom: .5rem;
}
