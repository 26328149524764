@each $name, $color in $organization_unit_type_colors {
  .organization-unit-type-badge.#{$name} {
    width: 3rem;
    height: 3rem;
    background: $color;
    svg {
      display: block;
      path {
        fill: white;
      }
      &.triangle {
        path {
          fill: $color;
        }
      }
    }
  }
}
