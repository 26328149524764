.organization {
  &.grid-item {
    .organization-unit-type-badges {
      svg {
        height: 2rem;
        width: auto;
      }
    }
  }
}

.products_filter {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: .75rem;

  a {
    border: solid 1px transparent;
    border-radius: $border-radius;
    color: $body-color;
    display: inline-block;
    margin-right: .5rem;
    padding: .25rem .5rem;
    text-align: center;
    text-decoration: none;

    &.active {
      box-shadow: $input-focus-box-shadow;
    }

    &.active,
    &:hover {
      color: $theme-color-primary;
    }

    &:hover {
      border: solid 1px $theme-color-primary;
    }

    img {
      width: 2rem;
    }
  }
}
