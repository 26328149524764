// Generate color classes
@each $color, $value in $grays {
  .color-gray-#{$color} {
    color: $value !important;
  }

  .bg-color-gray-#{$color} {
    background-color: $value !important;
  }
}

@each $color, $value in $colors {
  .color-#{$color} {
    color: $value !important;
  }

  .bg-color-#{$color} {
    background-color: $value !important;
  }
}

@each $color, $value in $theme-colors {
  .color-#{$color} {
    color: $value !important;
  }

  .bg-color-#{$color} {
    background-color: $value !important;
  }
}

@each $color, $value in $brand-colors {
  .color-#{$color} {
    color: $value !important;
  }

  .bg-color-#{$color} {
    background-color: $value !important;
  }
}

.light {
  opacity: .8;
}

.lighter {
  opacity: .6;
}
