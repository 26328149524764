.svg.icon {
  max-height: 6rem;
  max-width: 6rem;
}

.card-pre-bootstrap {
  &.hello-farmer,
  &.hello-there {
    header {
      background: white;
    }

    .content {
      text-align: center;
      padding: 2em 1em 1em;

      img {
        max-height: 120px;
      }
    }
  }

  &.hello-farmer {
    .content {
      font-size: .8rem;
    }
  }
}
