.content {
  ol,
  p,
  ul {
    margin-bottom: 1rem;
  }

  iframe {
    height: 350px;
    max-width: 100%;
    width: 100%;
  }
}

article.show.contact {
  .team {
    img {
      max-height: 200px;
      float: left;
      margin-right: 1em;
      margin-bottom: 2em;
    }
  }
}
