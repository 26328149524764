$line-height: 1.15;
$hb-red: #ff1432;
$cta-color: #017690;
$border-color: #eaeaea;
$border-color-button: #aaaaaa;
$border-color-button-hover: #333333;
$text-color: #000000;
$text-color-light: #555;
$link-color: #444;
$link-color-hover: $hb-red;
$border-radius: .2em;
$bg_gradient_start: #d4ffff;

$app-header-height: 2.5rem;
$next_content_minimal_visible_height: 4rem;
$default_v_padding: .5rem;
$default-h-padding: 1rem;
$default_padding: $default_v_padding $default-h-padding;
$default_v_margin: 1rem;
$default_h_margin: 0;
$default_margin: $default_v_margin $default_h_margin;

$organization_unit_type_colors: (
  farm: #ff0033,
  shop: #016450,
  restaurant: #0099cc,
  processor: #ffa08e,
  recreation: #017690,
  supportyourlocals: #d9685e,
  food_community: #d9685e
);

$resp_break: 800px;

html {
  line-height: $line-height;
}
