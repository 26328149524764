[data-controller='map'] {
  height: 100%;
  position: relative;
  width: 100%;
}

article {
  &.show {
    .description {
      margin: 1rem 0;
      max-width: 50em;
    }
  }
}
