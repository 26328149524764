// $sl-font-family: Arial, Baskerville, monospace;
$sl-overlay-background: #000;
$sl-navigation-color: #fff;
// $sl-caption-color: #fff;
// $sl-caption-background: #000;
$sl-overlay-opacity: .85;

// $sl-counter-fontsize: 1rem;
// $sl-caption-fontsize: 1rem;
// $sl-close-fontsize: 3rem;

// $sl-breakpoint-medium: 35.5em; // 568px, when 1rem == 16px
// $sl-breakpoint-large:  50em;   // 800px, when 1rem == 16px

// $sl-arrow-fontsize-small:  2rem;
// $sl-arrow-fontsize-medium: 3rem;
// $sl-arrow-fontsize-large:  3rem;
// $sl-img-border-small:  0 none;
// $sl-img-border-medium: 0 none;
// $sl-img-border-large:  0 none;
// $sl-iframe-border-small:  0 none;
// $sl-iframe-border-medium: 0 none;
// $sl-iframe-border-large:  0 none;

// $add-vendor-prefixes: true !default;

.lightbox {
  &.row {
    margin-bottom: 1.5rem;
    margin-left: -.25rem;
    margin-right: -.25rem;

    > div {
      padding: 0 .25rem .5rem;
    }
  }

  .photo {
    border-radius: $border-radius-sm;
    position: relative;
    transition: transform .25s ease;

    &:hover {
      box-shadow: $input-box-shadow, $input-focus-box-shadow;
      transform: scale(1.05);
    }

    .resource-actions {
      position: absolute;
      right: .25rem;
      top: .25rem;
    }
  }

  a.photo-link {
    background-position: center;
    background-size: cover;
    border-radius: $border-radius-sm;
    display: block;

    img {
      width: 100%;
    }
  }
}
