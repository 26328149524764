.controller-games-show,
.controller-games-answer,
.controller-game-steps-show {
  .breadcrumb, .footer, .quick-search {
    display: none !important;
  }

  [data-controller=map] {
    height: calc(100vh - 5.25rem);

    @include media-breakpoint-down(sm) {
      height: calc(50vh - 3rem);
    }
  }
}

.game-layout {
  .game-content {
    max-height: calc(100vh - 5.25rem);
    overflow-y: scroll;
    padding-right: 0;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse;

    .game-content {
      max-height: calc(50vh - 3rem);
      padding-right: 1rem;
    }

    .game-map {
      margin-bottom: 1rem;
    }
  }
}
