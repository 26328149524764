// Content copied from rails_bootstrap_forms gem scss
// https://github.com/bootstrap-ruby/bootstrap_form/blob/master/app/assets/stylesheets/rails_bootstrap_forms.css
.rails-bootstrap-forms-date-select select,
.rails-bootstrap-forms-time-select select,
.rails-bootstrap-forms-datetime-select select {
  display: inline-block;
  width: auto;
}

.rails-bootstrap-forms-error-summary {
  margin-top: 10px;
}
