.grid-item {
  background: $white;
  border: solid 1px $border-color;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  text-decoration: none;
  vertical-align: top;

  &:hover {
    border-color: $hb-red;
  }

  a {
    color: $black;
    text-decoration: none;
  }

  .resource-actions {
    position: absolute;
    right: 1rem;
    top: .5rem;
    z-index: 1;

    a {
      color: $theme-color-primary;
    }
  }

  .body {
    display: block;
    flex-grow: 1;
    padding: .5rem .75rem;
  }

  .title {
    color: $hb-red;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0;
    max-height: 3.5rem;
    overflow: hidden;
  }

  .from {
    color: $body-color;
    font-family: AmsiPro-SemiBold;
    font-size: .8rem;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  .distance {
    font-size: .8rem;
  }

  .footer {
    padding: .25rem .75rem .75rem;
  }

  .webshop {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .price {
      border-radius: $border-radius;
      font-family: AmsiPro-Black;
      font-size: 1.1rem;
      padding: .2rem .6rem .2rem 0;
    }

    .add-to-cart {
      background: $theme-color-light-green;
      color: $white;
      padding: .2rem .3rem;

      &.disabled {
        opacity: .5;
      }
    }
  }
}

.grid {
  &.row {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  > div {
    padding-bottom: 1rem;
    padding-left: .5rem;
    padding-right: .5rem;
  }
}
