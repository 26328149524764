.site-headline {
  font-size: 3rem;
}

.site-tagline {
  font-size: 1.125rem;
}

.section-title {
  font-size: 2rem;
}

.section-intro {
  font-size: 1.125rem;
  color: lighten($theme-text-color-secondary, 15%);
}

.controller-home-index {
  h2.color-shadow-green {
    @include media-breakpoint-down(xs) {
      font-size: 1.5rem;
    }
  }
}

.look-around {
  background-color: $theme-color-light-green-background;
  color: $theme-color-shadow-green;
  font-family: AmsiPro-Bold;
  font-size: 1.5rem;
  padding: 1rem 2rem;
  text-align: center;
  text-transform: uppercase;
}

.home-steps {
  text-align: center;

  img {
    max-width: 14rem;
    margin-bottom: .5rem;

    @include media-breakpoint-down(sm) {
      max-width: 5rem;
    }
  }
}

a.webshop-coop-link {
  color: inherit;
  width: 100%;

  &:hover {
    text-decoration: none;
  }
}

.fb-feed {
  h2 {
    color: $theme-color-shadow-green;
    font-family: AmsiPro-Bold;
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
  }

  margin-bottom: 3rem;
}
