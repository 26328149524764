.slider.glide.photos {
  width: 300px;

  .slider-item {
    position: relative;
    display: block;
    height: 200px;
    width: 300px;
    overflow: hidden;
    text-align: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    .resource-actions {
      position: absolute;
      bottom: 1rem;
      width: 100%;
    }
  }
}
