.columns {
  display: flex;
  .column {
    flex: 1;
    &.wide-column {
      flex: 2;
    }
    &.small-column {
      flex: 1;
    }
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
.flex-row {
  display: flex;
  & > * {
  }
}
@mixin small-view {
  .columns {
    display: unset;
    .column {
      &:not(:last-child) {
        margin-right: unset;
      }
    }
  }
}
.overlay {
  @include small-view;
}
@media(max-width: $resp_break) {
  @include small-view;
}
