.preview {
  display: flex;
  height: 5em;
  text-decoration: none;

  .header,
  .visual {
    display: block;
  }

  .visual {
    background-position: center;
    background-size: cover;
    flex-shrink: 0;
    width: 100px;
  }

  .header {
    flex: 1;
    padding: .4em;

    .title {
      font-size: 1em;
      margin-bottom: .3em;
    }

    .sub-title {
      font-size: .85em;
    }
  }
}

.previews {
  .preview {
    border-bottom: solid 1px $border-color;
  }
}
