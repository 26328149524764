.labels {
  display: block;
  margin: 1rem 0;
}
.label {
  background: $hb-red;
  color: white;
  text-transform: uppercase;
  font-size: 0.8rem;
  display: inline-block;
  padding: 0.2rem 0.5rem;
}
