.navbar {
  .active {
    .nav-link {
      font-weight: bold;
    }
  }

  .nav-item {
    .nav-link {
      background-color: $theme-color-light-pink;
      color: $theme-color-primary;
      font-size: .8rem;
      font-weight: 600;
      margin-bottom: .25rem;
      margin-right: .5rem;
      margin-top: .25rem;
      padding: .25rem .75rem;
    }

    .btn {
      @include media-breakpoint-down(md) {
        display: block;
        margin-right: 0;
        text-align: left;
      }

      margin-right: .25rem;
    }

    &.active {
      .btn-light-pink {
        background-color: $theme-color-primary;
        color: $white;
      }
    }

    &:last-child {
      a {
        margin-right: 0;
      }
    }
  }

  .btn-sm {
    margin: .25rem 0;
    padding: .25rem 1rem;
  }

  .hello-x {
    font-size: 1.2rem;
  }
}

.navbar-collapse {
  flex-grow: 0;
}

@include media-breakpoint-down(md) {
  .navbar-nav {
    padding-top: 1rem;
  }
}

.navbar-toggler {
  margin-right: .75rem;
}
