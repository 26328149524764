.product {
  .webshop {
    .btn {
      padding: .25rem .5rem;
      color: $white;
    }

    .price {
      background: $theme-text-color-secondary;
      font-family: AmsiPro-Black;
      font-size: 1.1rem;
    }

    .add-to-cart {
      background: $theme-color-primary;

      &.disabled {
        opacity: .5;
      }
    }
  }
}
