.slider.glide {
  .slider-item {
    width: 200px;
  }

  .glide__slides {
    align-items: stretch;
    margin: 0;
  }

  .glide__slide {
    height: auto;

    > div {
      height: 100%;
    }

    .grid-item {
      border: 0;
    }
  }

  .glide__arrow {
    color: $theme-color-light-pink;
    cursor: pointer;
    font-size: .8em;
    position: absolute;
    top: 40%;

    .fa-inverse {
      color: $theme-color-primary;
    }

    &:hover {
      color: darken($theme-color-light-pink, 7.5%);
    }
  }

  .glide__arrow--left {
    left: -1rem;
  }

  .glide__arrow--right {
    right: -1rem;
  }

  .glide__bullets {
    text-align: center;
  }

  .glide__bullet {
    background: $white;
    border: 0;
    border-radius: 5px;
    box-shadow: 0 0 2px #888;
    cursor: pointer;
    height: 10px;
    margin: 3px;
    padding: 0;
    width: 10px;

    &:hover {
      background: $theme-color-primary;
    }
  }

  .glide__bullet--active {
    background: $theme-color-primary;
  }

  .glide__track {}
}
