// $theme-width-restrict: 780px;

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: $theme-text-color-secondary;
  font-family: AmsiPro-Regular, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $theme-text-color-primary;
  font-family: AmsiPro-SemiBold, sans-serif;
  font-weight: 600;
}

code {
  @include border-radius(.25rem);
  background: $theme-bg-light;
  color: $theme-text-color-primary;
  padding: .25rem .375rem;
}

.table-dark {
  td,
  th,
  thead th {
    border-color: lighten($theme-text-color-primary, 20%);
  }
}

.popover {
  border-color: darken($theme-bg-light, 5%);
}

.bs-popover-auto[x-placement^="left"] > .arrow::before,
.bs-popover-left > .arrow::before {
  border-left-color: darken($theme-bg-light, 5%);
}

.bs-popover-auto[x-placement^="right"] > .arrow::before,
.bs-popover-right > .arrow::before {
  border-right-color: darken($theme-bg-light, 5%);
}

.bs-popover-auto[x-placement^="top"] > .arrow::before,
.bs-popover-top > .arrow::before {
  border-top-color: darken($theme-bg-light, 5%);
}

.bs-popover-auto[x-placement^="bottom"] > .arrow::before,
.bs-popover-bottom > .arrow::before {
  border-bottom-color: darken($theme-bg-light, 5%);
}

a.theme-link {
  color: $theme-text-color-primary;
  text-decoration: underline;
  -webkit-text-decoration-color: rgba($theme-text-color-primary, 0.3);
  text-decoration-color: rgba($theme-text-color-primary, 0.3);

  &:hover {
    color: $theme-color-primary;
    -webkit-text-decoration-color: rgba($theme-color-primary, 0.8);
    text-decoration-color: rgba($theme-color-primary, 0.8);
  }
}

.btn {
  font-weight: 600;
  padding: .6rem 1.25rem;
  @include transition(all .4s ease-in-out);
}

.btn-primary {
  background: $theme-color-primary;
}

.btn-secondary {
  background: $theme-text-color-secondary;
  color: #fff;
}

.btn-light {
  background: $theme-bg-light;
  border-color: $theme-bg-light;
  color: $gray-700;

  &:hover {
    background: $theme-bg-light;
    color: $gray-700;
  }
}

/* prevent owl carousel FOUC */
.owl-item {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
}

.error {
  color: $theme-error;
}

.theme-icon-holder {
  @include border-radius(50%);

  background: $theme-bg-light;
  color: $theme-color-primary;
  display: inline-block;
  font-size: 1rem;
  height: 32px;
  padding-top: 4px;
  text-align: center;
  width: 32px;

  .svg-inline---fa {
    color: $theme-color-primary;
  }
}

.theme-bg-light {
  background: $theme-bg-light;
}

.theme-bg-dark {
  background: $theme-bg-dark;
}

.theme-bg-shapes-right {
  height: 100%;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  &:before {
    content: '';
    width: 300px;
    height: 150px;
    background: rgba(256, 256, 256, 0.1);
    position: absolute;
    right: -60px;
    top: 0;
    @include border-radius(4px);
    @include skew(25, -10);
    @include transform-origin(top left);
  }

  &:after {
    content: '';
    width: 150px;
    height: 600px;
    background: rgba(256, 256, 256, 0.15);
    position: absolute;
    right: 0;
    top: 30px;
    @include border-radius(6px);
    @include skew(25, 10);
    @include transform-origin(top left);
  }
}

.theme-bg-shapes-left {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  overflow: hidden;

  &:before {
    content: '';
    width: 300px;
    height: 150px;
    background: rgba(256, 256, 256, 0.1);
    position: absolute;
    left: -90px;
    top: 0;
    @include border-radius(6px);
    @include skew(25, -12);
    @include transform-origin(top left);
  }

  &:after {
    content: '';
    width: 150px;
    height: 600px;
    background: rgba(256, 256, 256, 0.15);
    position: absolute;
    left: 0;
    top: 30px;
    @include border-radius(4px);
    @include skew(-25, 10);
    @include transform-origin(top left);
  }
}

/*********** Theme Utilities **********/

.border-radius-0 {
  @include border-radius(0);
}

.font-style-normal {
  font-style: normal !important;
}

.single-col-max {
  max-width: $single-col-max;
}

.tech-icons {
  font-size: 1.875rem;
  color: $theme-color-primary;

  .fa-angular {
    color: #ca3327;
  }

  .fa-js,
  .fa-js-square {
    color: #f1de4f;
  }

  .fa-react {
    color: #62d4fa;
  }

  .fa-vuejs {
    color: #64b687;
  }

  .nodejs {
    color: #55ac63;
  }

  .fa-python {
    color: #456e9c;
  }

  .fa-php {
    color: #777baf;
  }

  .fa-java {
    color: #2e68ab;
  }

  .fa-html5,
  .fa-html5-alt {
    color: #de6e3c;
  }

  .fa-css3,
  .fa-css3-alt {
    color: #53a7dc;
  }

  .fa-sass {
    color: #bf6b97;
  }

  .fa-less {
    color: #324b7c;
  }

  .fa-gulp {
    color: #c9514e;
  }

  .fa-grunt {
    color: #efac42;
  }

  .fa-npm {
    color: #bb443e;
  }

  .fa-sketch {
    color: #fa6400;
  }

  .fa-bootstrap {
    color: #7952b3;
  }

  .fa-cc-stripe,
  .fa-stripe {
    color: #6972de;
  }

  .fa-cc-paypal,
  .fa-paypal {
    color: #449ad8;
  }

  .fa-apple-pay,
  .fa-cc-apple-pay {
    color: black;
  }

  .fa-github,
  .fa-github-alt,
  .fa-github-square {
    color: #24292e;
  }

  .fa-linux {}

  .fa-firefox {
    color: #efa239;
  }

  .fa-chrome {
    color: #4b9f65;
  }
}

.site-logo {
  flex-grow: 1;
  white-space: nowrap;

  .logo-text {
    color: $theme-text-color-primary;
    font-size: 1.5rem;
    font-weight: bold;
  }

  .text-alt {
    font-weight: 300;
  }

  .navbar-brand {
    font-size: 2rem;
    font-weight: 600;
    padding-top: 0;
  }
}

.navbar {
  font-size: 1rem;
  padding: .5rem 0;

  .dropdown-toggle::after {
    position: relative;
    top: .2rem;
    vertical-align: .3em;
  }

  .navbar-toggler {
    border: 0;
    font-size: inherit;
    padding: 0;

    &:focus {
      outline: none;
    }
  }

  .nav-link {
    color: $theme-text-color-secondary;
    font-weight: 500;

    &:hover {
      color: $theme-text-color-primary;
    }
  }

  .active {
    .nav-link {
      color: $theme-text-color-primary;
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;

    &::after {
      border-bottom: 8px solid #fff;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      right: 2rem;
      top: -8px;
      width: 0;
    }

    &::before {
      border-bottom: 8px solid $theme-divider;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      right: 2rem;
      top: -9px;
      width: 0;
    }
  }

  .dropdown-item {
    font-size: .875rem;
    padding: .75rem 1.5rem;

    &.has-icon {
      padding-left: 1rem;
    }

    .theme-icon-holder {
      font-size: .75rem;
      padding-top: .5rem;
    }

    &.active,
    &:active,
    &:hover {
      background: $theme-bg-light;
      color: $theme-color-primary;

      .theme-icon-holder {
        background: $theme-color-primary;
        color: $white;
      }
    }
  }
}

/* Page headers */
.page-header {
  &.theme-bg-dark {
    .page-heading {
      color: $white;
    }

    .page-intro {
      color: $white;
      @include opacity(.8);
    }
  }
}

/* Animated Hamburger Icon for Bootstrap */

.navbar-toggler {
  position: relative;
}

.navbar-toggler:active,
.navbar-toggler:focus {
  outline: 0;
}

.navbar-toggler span {
  @include border-radius(1px);
  @include opacity(1);
  background-color: $theme-color-primary;
  display: block;
  height: 3px;
  left: 0;
  margin-bottom: 5px;
  margin-top: 5px;
  position: relative;
  transform: rotate(0deg);
  width: 24px;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform .35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  @include opacity(.9);
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(135deg);
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  background-color: transparent;
  height: 12px;
  visibility: hidden;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  left: 0;
  opacity: .9;
  position: absolute;
  top: 0;
  transform: rotate(-135deg);
}

.social-list {
  li {
    @include border-radius(50%);
    background: $theme-bg-light;
    font-size: 1.125rem;
    height: 32px;
    padding-top: 2px;
    text-align: center;
    width: 32px;

    a {
      color: lighten($theme-color-primary, 10%);

      &:hover {
        color: $theme-color-primary;
      }
    }

    &:not(:last-child) {
      margin-right: .875rem;
    }
  }
}

.header {
  @include box-shadow(0 1px 4px 0 rgba(0, 0, 0, 0.1));
  background: #fff;
}

/* Footer */
.footer {
  .footer-col {
    font-size: .875rem;

    .col-heading {
      color: $theme-text-color-secondary;
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    a {
      color: $theme-text-color-secondary;
    }

    li {
      margin-bottom: .5rem;
    }
  }

  .copyright {
    font-size: .875rem;
  }
}

.newsletter-section {
  .section-intro {
    font-size: 1rem;
  }
}

.signup-form .form-control {
  width: 320px;
}

/* Page related */
.page-heading {
  font-size: 2.5rem;
}

.page-intro {
  font-size: 1.125rem;
}

/* dropdown menu animation */
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(1rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0rem);
  }

  0% {
    opacity: 0;
    transform: translateY(1rem);
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }

  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .menu-animate {
    animation-duration: .3s;
    animation-fill-mode: both;
  }

  // Make dropdown menu drop on hover
  .navbar .dropdown:hover > .dropdown-menu {
    display: block;
  }

  .navbar .dropdown > .dropdown-toggle:active {
    /*Without this, clicking will make it sticky*/
    pointer-events: none;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {}

/* We occasionally use media queries that go in the other direction */
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .signup-form .form-control {
    width: inherit;
  }

  .signup-form .form-group {
    width: 100%;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .navbar .collapse .dropdown-menu {
    @include box-shadow(none !important);
    border: 0;

    &:after,
    &:before {
      display: none;
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {}
