.btn {
  transition: none;

  &:hover {
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }
}

.resource-link-compact {
  @include border-radius($btn-border-radius-sm);
  display: inline-block;
  padding: 0.2rem 0.35rem;

  &:hover {
    background-color: $theme-color-light-pink;
  }
}

.btn-light-pink, .btn-light-pink:hover {
  color: $theme-color-primary;
}
