.toggle {
  display: inline-block;
  height: 1.5em;
  line-height: 1.5em;
  width: 1.5em;
  text-align: center;
  text-decoration: none;
  border-radius: 0.75em;
  &.off {
    background: white;
    color: black;
    opacity: 0.4;
  }
  &.on, &:hover {
    background: black;
    color: white;
  }
}
