.ui-pnotify-container {
  padding: .5rem .8rem !important;

  @include media-breakpoint-down(xs) {
    width: calc(100vw - 1rem) !important;
  }
}

body > .ui-pnotify {
  z-index: 1 !important;
}
