nav {
  a {
    text-decoration: none;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
    }
  }
}

main {
  clear: both;
}

main > section {
  &.padded {
    padding: 1em 3em;
  }
}

section {
  .photos {
    margin: 1.5rem 0;
  }
}

.h1 {
  color: $hb-red;
}

.h2 {
  font-size: 1.5rem;
}

.flex {
  display: flex;
}

.actions {
  margin: 1rem 0;
}

.exception-message {
  border: solid 1px $black;
  font-size: .8rem;
  margin: $default-margin;
  padding: $default-padding;
}

@media (max-width: $resp_break) {
  main > section {
    &.padded {
      padding: .85rem;
    }
  }

  .flex {
    display: block;
  }

  .h1 {
    font-size: 1.5rem;
  }
}
