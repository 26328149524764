// body {
//   font-family: AmsiPro-Regular;
//   font-weight: normal;
//   font-style: normal;
// }
.h1,
.title {
  font-family: AmsiPro-SemiBold;
  font-weight: normal;
  font-style: normal;
}
// .menu,
// .button {
//   font-family: AmsiPro-Regular;
//   font-weight: normal;
// }
