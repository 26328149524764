.filterable-content {
  position: relative;

  .filterable-paging-bar {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .filters-and-content {
    margin-top: 0.75rem;

    .filter-panel {
      display: block;
      position: relative;
      max-height: calc(100vh - 5.25rem);
      overflow: scroll;

      .filters {
        .unit-types-filter {
          @each $name, $color in $organization_unit_type_colors {
            input.#{$name} + label {
              svg path {
                fill: $color;
              }
            }
          }
        }

        .input {
          display: block;

          & > label {
            display: block;
            width: 100%;
          }
        }
      }

      .actions {
        background: transparent;
        margin: 1rem 0;
        text-align: center;
      }
    }
  }

  .filter-panel-toggle {
    display: none;
  }

  &.display-map {
    [data-controller="map"] {
      height: calc(100vh - 7rem);
    }
  }
}

.filters {
  .title {
    color: $theme-color-primary;
    font-size: 1.2rem;
    text-align: left;
  }

  .form-group > label {
    font-weight: bold;
  }

  .filter-checkboxes {
    label.form-check-label {
      border-radius: 5px;
      cursor: pointer;
    }

    input:checked + label,
    label:hover {
      color: $theme-color-primary;

      > img,
      > svg,
      > .placeholder {
        border-color: $input-focus-border-color;
      }
    }

    input:checked + label {
      > img,
      > svg,
      > .placeholder {
        box-shadow: $input-focus-box-shadow;
      }
    }

    .checkbox,
    .form-check {
      padding-left: 0;
    }

    input[type="checkbox"] {
      opacity: 0;
      position: absolute;
    }

    > .col-6 {
      padding-right: 0.125rem;

      + .col-6 {
        padding-left: 0.125rem;
        padding-right: 1rem;
      }
    }
  }

  .filter-small-img-with-label {
    label {
      align-items: center;
      display: flex;
      margin-bottom: 0.4rem;
    }

    .placeholder,
    img {
      border: solid 1px $input-border-color;
      border-radius: 0.25rem;
      display: inline-block;
      height: 1.75rem;
      margin-right: 0.4rem;
      padding: 0.1rem;
      vertical-align: middle;
      width: 1.75rem;
    }

    .name {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc(100% - 2rem);
    }
  }

  .filter-big-img-no-label {
    display: flex;
    flex-wrap: wrap;

    label {
      margin: 0 0.25rem 0.25rem 0;
      padding: 0.25rem;
    }

    .placeholder,
    img,
    svg {
      border: solid 1px transparent;
      border-radius: 0.25rem;
      display: inline-block;
      height: 2.5rem;
      width: 2.5rem;
    }

    .name {
      display: none;
    }
  }
}

.top-paging-bar {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 0.75rem;

  .form-group {
    margin-bottom: 0;
  }

  .form-inline {
    .form-control {
      height: 1.8rem;
      padding: 0.1rem 0.5rem;
    }
  }
}

.display-options {
  button {
    font-weight: bold;
    padding-right: 0;
    border: 0;
    background: none;

    &::after {
      color: #888;
      font-weight: normal;
      padding-left: 0.5rem;
      content: "|";
    }

    &:last-child {
      &::after {
        // padding: 0;
        content: "";
      }
    }
  }
}

.display-map {
  .hotspot-selector,
  .top-paging-bar,
  .display-options {
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 4px;
    color: darken($map-overlay-color, 20%);
    position: absolute;
    z-index: 1;
  }

  .top-paging-bar {
    padding: 0.25rem 0.5rem;
    right: 1.5rem;
    bottom: 0.5rem;
  }

  .display-options {
    padding: 0.25rem 0.5rem;
    right: 1.5rem;
    top: 2.5rem;
    padding: 0;

    button {
      color: darken($map-overlay-color, 20%);
    }
  }

  .hotspot-selector {
    left: 1rem;
    padding: 0;
    top: 2rem;

    > a {
      color: darken($map-overlay-color, 20%);
      display: inline-block;
      padding: 0.25rem 0.5rem;
      text-decoration: none;
    }

    @include media-breakpoint-down(sm) {
      left: 1.5rem;
    }

    &.expanded {
      .panel {
        top: 1.8rem;
      }
    }
  }

  .ol-zoom {
    top: 0.5em;
  }
}

.resource-type-selector {
  display: flex;
  justify-content: space-between;

  .resources {
    a,
    button {
      background-color: $theme-color-light-green;
      border-radius: $border-radius;
      border: none;
      color: $white;
      display: inline-block;
      font-size: 0.8rem;
      font-weight: bold;
      padding: 0.15rem 0.75rem;
      text-transform: uppercase;

      &.active {
        background-color: $theme-color-shadow-green;
      }
    }

    @include media-breakpoint-down(xs) {
      max-width: calc(100% - 8rem);
    }
  }

  .location-selector {
    font-weight: bold;
    background-color: rgba($white, 0.75);
  }
}

@include media-breakpoint-down(md) {
  .filterable-content {
    .top-bar {
      height: unset;
    }

    .filter-panel-toggle {
      display: inline-flex;
      flex-grow: 0;
      width: auto;
    }

    .filters-and-content {
      .filter-panel,
      .filtered-content {
        display: block;
        height: 100%;
        width: 100%;
      }

      .filter-panel {
        background: $white;
        box-shadow: 0 0 5px #444;
        height: calc(100% - 20px);
        left: 10px;
        padding-top: 0.5rem;
        position: fixed;
        top: 10px;
        width: calc(100% - 20px);
        z-index: 2000;

        &:not(.small-screen-visible) {
          display: none;
        }

        .filters {
          height: calc(100% - 0.5rem);
          overflow-y: auto;
        }
      }
    }
  }
}
