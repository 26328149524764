.breadcrumb {
  background: none;
  font-size: .8rem;

  ul {
    li {
      display: inline-block;

      a {
        &:after {
          margin: 0 .4em;
          content: '\2192';
        }
      }
    }
  }

  .action-buttons {
    margin-bottom: -.25rem;
    margin-top: -.25rem;

    .btn-sm {
      padding: .25rem .5rem;
    }
  }
}
