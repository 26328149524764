form {
  .errors {
    color: red;

    &.base {
      border: solid 2px red;
      background: lighten(red, 45%);
      padding: $default-padding;
      list-style-position: inside;
    }
  }

  .field_with_errors {
    label {
      color: red;
    }

    .error {
      margin-left: 12rem;
      color: red;
      font-style: italic;
    }
  }

  .input {
    .hint {
      display: block !important;
      margin: $default_v_margin 0 $default_v_margin 12rem;
      font-style: italic;
      color: $text_color_light;
      font-size: .85rem;
    }

    .collection {
      & > * {
        display: block;
      }
    }

    &.file {
      img {
        max-height: 3rem;
      }
    }
  }

  .actions {
    padding: $default_padding;
    margin: $default_margin;
    background: #efefef;

    .cancel {
      margin-left: 1rem;
    }
  }

  .address-with-geocode {
    position: relative;

    .map-input-button {
      height: 20rem;
      position: relative;

      .input-and-button {
        background: rgba(255,255,255,0.8);
        left: .5rem;
        padding: $default_padding;
        position: absolute;
        top: .5rem;
        z-index: 100;

        .input-group {
          width: 20rem;
        }

        .input {
          margin: 0;
        }

        & > * {
          display: inline-block;
        }
      }

      .invalid-feedback {
        display: none;
      }
    }
  }
}

form:not(.filterable-content) {
  .input {
    & > * {
      display: inline-block;
      vertical-align: top;
      width: 40em;
      max-width: 60em;
    }

    & > label {
      width: 12rem;
      flex-shrink: 0;
    }
  }
}
