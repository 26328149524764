.hotspot-selector {
  padding: .75rem;
  position: relative;

  a {
    color: $black;
  }

  .panel {
    display: none;
    padding: .5rem .5rem 0;
    z-index: 3000;
  }

  &.expanded {
    .toggle {
      background: $white;
      z-index: 2000;
    }

    .panel {
      display: block;
      left: 0;
      max-height: 100vh;
      min-width: 15rem;
      position: absolute;
      top: 2.8rem;
    }
  }

  .button {
    display: block;
    text-align: center;
  }

  .info {
    color: #888;
    display: none;
    font-size: .7rem;
    line-height: 1.2em;
    margin: .5rem 0 .75rem;
    text-align: center;

    &.visible {
      display: block;
    }
  }

  ul.predefined {
    margin-bottom: .25rem;
    padding-left: 0;

    li {
      display: block;
      padding: .25rem 0;

      a:after {
        content: '\2192';
        float: right;
      }

      &:not(:last-child) {
        border-bottom: solid 1px $border-color;
      }

      a {
        display: block;
      }
    }
  }
}
