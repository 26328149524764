.popup {
  background-color: rgba($white, 0.4);
  border-radius: 0.2rem;
  left: 50px;
  padding: 0.2rem;
  position: absolute;
  top: 0.5em;
  width: 240px;
  z-index: 1000;
}

.close-popup {
  color: $theme-color-primary;
  cursor: pointer;
  font-size: 0.8em;
  position: absolute;
  right: -0.5rem;
  top: -0.3rem;

  &:hover {
    color: $theme-color-light-pink;

    .fa-inverse {
      color: $theme-color-primary;
    }
  }
}

.hover {
  background-color: $white;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  position: absolute;
  white-space: nowrap;
}

.ol-attribution {
  display: none;
}
