.hello-x {
  display: inline-block;
  font-family: AmsiPro-Black;
  letter-spacing: .05rem;
  position: relative;
  text-transform: uppercase;

  .alternate-x,
  .hello,
  .x {
    display: inline-block;
  }

  .hello {
    background: $theme-color-primary;
    border-radius: $border-radius / 2;
    color: $white;
    margin-right: .3em;
    padding: 0 .25em 0 .3em;

    &::before {
      background: url('../img/triangle.svg') no-repeat;
      bottom: -.39em;
      content: '';
      height: .39em;
      left: .52em;
      position: absolute;
      width: .52em;
    }
  }

  .x {
    color: $black;
    position: relative;

    span {
      animation-iteration-count: infinite;
    }

    @for $i from 1 through 8 {
      &.size-#{$i} {
        span {
          animation-duration: 2s * $i;
          animation-name: blinkX-#{$i};
        }
      }
    }

    @for $i from 1 through 7 {
      .alternate-#{$i} {
        animation-delay: 2s * $i;
        left: 0;
        position: absolute;
        visibility: hidden;
      }
    }
  }
}

@for $i from 1 through 8 {
  @keyframes blinkX-#{$i} {
    from {
      visibility: visible;
    }

    #{100 / $i}% {
      visibility: hidden;
    }

    to {
      visibility: hidden;
    }
  }
}
