textarea {
  min-height: 3.5rem;

  &.form-control {
    height: auto;
  }
}

[aria-role="button"] {
  cursor: pointer;
}

.input-counter {
  font-size: 85%;
  float: right;
}
