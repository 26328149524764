.social-media-links {
  display: block;
  margin: 1rem 0;

  a {
    display: inline-block;
    margin-right: 1rem;
    height: 2rem;
  }
  
  svg {
    display: inline-block;
    height: 2rem;
    width: 2rem;
  }
}
